import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from '../ui/pages/LandingPage'
import AuthHeader from '../customComponent/AuthHeader';
import PrivacyPolicy from '../ui/pages/PrivacyPolicy';
import TermsConditions from '../ui/pages/TermsConditions';
import FairPlay from '../ui/pages/FairPlay';
import ResponsibleGaming from '../ui/pages/ResponsibleGaming';
import AntiMoneyLaundering from '../ui/pages/AntiMoneyLaundering';  
import RefundPolicy from '../ui/pages/RefundPolicy';
import ContactUs from '../ui/pages/ContactUs'; 
import LegalPolicy from '../ui/pages/LegalPolicy';

const Routing = () => {



    return (
        <Router>
            <AuthHeader />
            <Routes>
                <Route exact path="/" element={<LandingPage />}></Route>
                <Route exact path="/privacypolicy" element={<PrivacyPolicy />}></Route>
                <Route exact path="/termsconditions" element={<TermsConditions />}></Route>
                <Route exact path="/fairplay" element={<FairPlay />}></Route>
                <Route exact path="/responsible-gaming-policy" element={<ResponsibleGaming />}></Route>
                {/* <Route exact path="/anti-money-laundering-policy" element={<AntiMoneyLaundering />}></Route> */}
                <Route exact path="/refund-policy" element={<RefundPolicy />}></Route>
                <Route exact path="/contact" element={<ContactUs />}></Route>  
                <Route exact path="/legality" element={<LegalPolicy />}></Route>  
            </Routes>
        </Router>

    )
}

export default Routing
