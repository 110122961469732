import React from 'react'

const FairPlay = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> KheloIndiaKhelo FairPlay </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> FairPlay </li>
                        </ol>
                    </nav>
                </div>
            </div>
            
            
            <main>
                <section class="mt-4 pt-4">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">You will not be able to play games or withdraw cash during the banned period. You will lose your winnings from the games where cheating has been done. You will be permanently blocked if you are caught cheating in any game, promoting hacks or violating other terms of use at KheloIndiaKhelo'S discretion. In case of a permanent block, any balances on your KheloIndiaKhelo Wallets will be forfeited by KheloIndiaKhelo .</div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>KheloIndiaKhelo Security</h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">KheloIndiaKhelo uses best in class data security practices and tools to ensure the security and privacy of user data. All our systems are regularly tested using automated and manual processes for security not just by KheloIndiaKhelo but by external security experts as well. At KheloIndiaKhelo , we give data protection the highest priority and ensure there are no gaps in protecting our users’ security and privacy.</div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>Secure payments</h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">All information is stored in a secure environment and is not shared with any untrusted third party. We work with trusted third-party payment gateways to ensure the safety and security of all financial transactions. We do not store your credit/debit card details with us. We have additional OTP verifications to prevent fraudulent withdrawals. Prompt response is provided for any issues identified or reported.</div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>Game fraud detection</h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">KheloIndiaKhelo has automated detection systems to identify fraudulent behaviour in games. Anti-collusion measures are taken in rummy games during the gameplay.</div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>Responsible Gaming</h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">We notify users in case their accrued spending exceeds a certain limit and ask them to play responsibly. Users are notified when their game sessions are long to take an optional break. When the session exceeds a certain time, KheloIndiaKhelo mandates the user to take a non-negotiable break. The user, in this case, cannot use the app.</div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>Disconnection</h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">Users disconnected due to poor internet connectivity will be declared lost. Please check your internet connection before you start playing. KheloIndiaKhelo is not responsible for internet connections of individual players. We strongly recommend testing your internet connection by playing free/token tournaments before registering yourself on paid tournaments.</div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>Profanity and Abuse</h2></div>
                </section>
                <section class="mb-5 pb-5">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">KheloIndiaKhelo does not permit the usage of profane, vulgar or abusive language in chat or usernames or otherwise.</div>
                        </div>
                    </div>
                </section>
            </main>


            {/* <!-- Copyright Area Start --> */}
            <div className="copyright-area  ptb-70 pt-0 pb-4">
                <div className="container">
                    {/* <!-- Contact address left --> */}
                    <div className="conct-border row align-items-center">
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" /> </a>
                        </div>
                        <div className="col-md-7">
                            <div className="single-address">
                                <a href="/fairplay"  >KheloIndiaKhelo Fairplay</a>
                                <a href="/termsconditions">Terms & Conditions</a>
                                <a href="/privacypolicy">Privacy Policy</a>
                                <a href="/legality"> Legality </a>
                                <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                {/* <a href="/anti-money-laundering-policy">Anti-Money Laundering  </a> */}
                                <a href="/refund-policy"> Refund Policy  </a>
                                <a href="/contact"> Contact us    </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Contact address left --> */}
                    {/* <!-- Copyright right --> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area text-center">
                                {/* <!-- Copyright social --> */}
                                {/* <!-- <div className="contact-social text-center ptb-50 pb-35">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-vimeo"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-google-plus"></i></a>
                                        </li>
                                    </ul>
                                </div> --> */}
                                {/* <!-- Copyright social --> */}
                                <div className="copyright-text">
                                    <p>Copyright &copy;  <a href="#"> Khelo India Khelo </a> All Rights Reserved.  Design by <a href="https://appinop.com" target="_blank"  >Appinop Technologies</a><br/>KheloIndiaKhelo is a part of  Khelo India Khelo Pvt. Ltd.</p>


                                    <p>
                                        <b>DISCLAIMER</b><br/>
                                        This game may be habit-forming or financially risky. Play responsibly. KheloIndiaKhelo is the biggest social gaming app in India by number of games, languages and exciting formats on the platform. KheloIndiaKhelo is only available for people who are above 18 years of age. KheloIndiaKhelo is available only in those Indian states where skill gaming is allowed by regulations.Khelo India Khelo Pvt. Ltd. is the sole owner of and reserves the right to “KheloIndiaKhelo” trademark, logos, assets, content, information, etc. used in the website except the third party content. Khelo India Khelo Pvt. Ltd. does not acknowledge the accuracy or reliability of third party content.
                                    </p>
                                    
                                </div>
                                {/* <!-- Copyright text --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Copyright right --> */}
                </div>
            </div >
            {/* <!-- Copyright Area End --> */}
        </>
    )
}

export default FairPlay
