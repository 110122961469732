import React from 'react'

const RefundPolicy = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> Refund Policy </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> Refund Policy </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="common_content_box">

            <div id="privacypolicy" class=" container">
                 
                <h4>
                Refund Policy</h4> 
                <p>Thanks for being a patron with KheloIndiaKhelo. If you are not entirely satisfied with your subscription, we are here to help.</p> 

                <h4 className='mt-3' >Refund</h4>
                <p>
                Once we receive your Refund request, we will inspect it and notify you on the status of your refund.
                </p>
                <p>
                If your refund request is approved, we will initiate a refund to your credit card (or original method of payment) within 7 working days. You will receive the credit within a certain amount of days, depending on your card issuer's policies.
                </p>
                <p>
                In case of unforeseen technical glitch, KheloIndiaKhelo would refund subscription upon reviewing the complaint. Final decision lies with the company.
                </p>




                </div>

            </section>
            {/* <!-- Copyright Area Start --> */}
            <div className="copyright-area  ptb-70 pt-0 pb-4">
                <div className="container">
                    {/* <!-- Contact address left --> */}
                    <div className="conct-border row align-items-center">
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" /> </a>
                        </div>
                        <div className="col-md-7">
                            <div className="single-address">
                                <a href="/fairplay"  >KheloIndiaKhelo Fairplay</a>
                                <a href="/termsconditions">Terms & Conditions</a>
                                <a href="/privacypolicy">Privacy Policy</a>
                                <a href="/legality"> Legality </a>
                                <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                {/* <a href="/anti-money-laundering-policy">Anti-Money Laundering  </a> */}
                                <a href="/refund-policy"> Refund Policy  </a>
                                <a href="/contact"> Contact us    </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Contact address left --> */}
                    {/* <!-- Copyright right --> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area text-center">
                                {/* <!-- Copyright social --> */}
                                {/* <!-- <div className="contact-social text-center ptb-50 pb-35">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-vimeo"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-google-plus"></i></a>
                                        </li>
                                    </ul>
                                </div> --> */}
                                {/* <!-- Copyright social --> */}
                                <div className="copyright-text">
                                    <p>Copyright &copy;  <a href="#"> Khelo India Khelo </a> All Rights Reserved.  Design by <a href="https://appinop.com" target="_blank"  >Appinop Technologies</a><br/>KheloIndiaKhelo is a part of  Khelo India Khelo Pvt. Ltd.</p>
                                
                                    <p>
                                        <b>DISCLAIMER</b><br/>
                                        This game may be habit-forming or financially risky. Play responsibly. KheloIndiaKhelo is the biggest social gaming app in India by number of games, languages and exciting formats on the platform. KheloIndiaKhelo is only available for people who are above 18 years of age. KheloIndiaKhelo is available only in those Indian states where skill gaming is allowed by regulations.Khelo India Khelo Pvt. Ltd. is the sole owner of and reserves the right to “KheloIndiaKhelo” trademark, logos, assets, content, information, etc. used in the website except the third party content. Khelo India Khelo Pvt. Ltd. does not acknowledge the accuracy or reliability of third party content.
                                    </p>
                                </div>
                                {/* <!-- Copyright text --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Copyright right --> */}
                </div>
            </div >
            {/* <!-- Copyright Area End --> */}
        </>
    )
}

export default RefundPolicy
